import React from 'react';
import '../../styles/moderntheme.css';

const Privacy = () => {
  return (
    <div className="container">
          
    </div>
  );
};

export default Privacy;