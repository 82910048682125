import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faHeart, faComment, faShare, faBookmark, faRetweet, 
  faEllipsisH, faGlobe, faCheck, faThumbsUp, 
  faPlay, faPause, faVolumeMute, faVolumeUp,
  faRepeat, faMusic
} from '@fortawesome/free-solid-svg-icons';

const PlatformPreview = ({ platform, content, media, user = { 
  name: 'John Doe',
  username: 'johndoe',
  avatar: null,
  verified: true,
  title: 'Software Engineer at Tech Company',
  followers: '10.2K',
  views: '1.2M',
  duration: '0:30'
} }) => {
  const [liked, setLiked] = useState(false);
  const [bookmarked, setBookmarked] = useState(false);
  const [retweeted, setRetweeted] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);
  
    const renderFacebookPreview = () => (
      <div className="fb-post">
        <div className="fb-header">
          {user.avatar ? (
            <img src={user.avatar} alt="Profile" className="fb-avatar" />
          ) : (
            <div className="fb-avatar-placeholder" />
          )}
          <div className="fb-user-info">
            <span className="fb-name">{user.name}</span>
            <div className="fb-post-meta">
              <span>1h</span>
              <span>·</span>
              <FontAwesomeIcon icon={faGlobe} className="fb-globe" />
            </div>
          </div>
          <FontAwesomeIcon icon={faEllipsisH} className="fb-more" />
        </div>
        
        <div className="fb-content">{content}</div>
        
        {media && (
          <div className="fb-media">
            {media.type.startsWith('image/') ? (
              <img src={URL.createObjectURL(media)} alt="Post media" />
            ) : (
              <video src={URL.createObjectURL(media)} controls />
            )}
          </div>
        )}
        
        <div className="fb-stats">
          <div className="fb-reactions">
            <span className="fb-like-count">
              {liked ? '1' : '0'}
            </span>
          </div>
          <div className="fb-comments-shares">
            <span>0 comments</span>
            <span>0 shares</span>
          </div>
        </div>
        
        <div className="fb-actions">
          <button 
            className={`fb-action-btn ${liked ? 'liked' : ''}`}
            onClick={() => setLiked(!liked)}
          >
            <FontAwesomeIcon icon={faHeart} />
            <span>Like</span>
          </button>
          <button className="fb-action-btn">
            <FontAwesomeIcon icon={faComment} />
            <span>Comment</span>
          </button>
          <button className="fb-action-btn">
            <FontAwesomeIcon icon={faShare} />
            <span>Share</span>
          </button>
        </div>
      </div>
    );
  
    const renderTwitterPreview = () => (
      <div className="twitter-post">
        <div className="twitter-header">
          {user.avatar ? (
            <img src={user.avatar} alt="Profile" className="twitter-avatar" />
          ) : (
            <div className="twitter-avatar-placeholder" />
          )}
          <div className="twitter-user-info">
            <div className="twitter-names">
              <span className="twitter-display-name">
                {user.name}
                {user.verified && (
                  <span className="twitter-verified">
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                )}
              </span>
              <span className="twitter-username">@{user.username}</span>
            </div>
          </div>
          <FontAwesomeIcon icon={faEllipsisH} className="twitter-more" />
        </div>
        
        <div className="twitter-content">{content}</div>
        
        {media && (
          <div className="twitter-media">
            {media.type.startsWith('image/') ? (
              <img src={URL.createObjectURL(media)} alt="Post media" />
            ) : (
              <video src={URL.createObjectURL(media)} controls />
            )}
          </div>
        )}
        
        <div className="twitter-actions">
          <button className="twitter-action-btn">
            <FontAwesomeIcon icon={faComment} />
            <span>0</span>
          </button>
          <button 
            className={`twitter-action-btn ${retweeted ? 'retweeted' : ''}`}
            onClick={() => setRetweeted(!retweeted)}
          >
            <FontAwesomeIcon icon={faRetweet} />
            <span>{retweeted ? '1' : '0'}</span>
          </button>
          <button 
            className={`twitter-action-btn ${liked ? 'liked' : ''}`}
            onClick={() => setLiked(!liked)}
          >
            <FontAwesomeIcon icon={faHeart} />
            <span>{liked ? '1' : '0'}</span>
          </button>
          <button 
            className={`twitter-action-btn ${bookmarked ? 'bookmarked' : ''}`}
            onClick={() => setBookmarked(!bookmarked)}
          >
            <FontAwesomeIcon icon={faBookmark} />
          </button>
        </div>
      </div>
    );
  
    const renderInstagramPreview = () => (
      <div className="instagram-post">
        <div className="instagram-header">
          {user.avatar ? (
            <img src={user.avatar} alt="Profile" className="instagram-avatar" />
          ) : (
            <div className="instagram-avatar-placeholder" />
          )}
          <span className="instagram-username">{user.username}</span>
          <FontAwesomeIcon icon={faEllipsisH} className="instagram-more" />
        </div>
        
        {media && (
          <div className="instagram-media">
            {media.type.startsWith('image/') ? (
              <img src={URL.createObjectURL(media)} alt="Post media" />
            ) : (
              <video src={URL.createObjectURL(media)} controls />
            )}
          </div>
        )}
        
        <div className="instagram-actions">
          <div className="instagram-primary-actions">
            <button 
              className={`instagram-action-btn ${liked ? 'liked' : ''}`}
              onClick={() => setLiked(!liked)}
            >
              <FontAwesomeIcon icon={faHeart} />
            </button>
            <button className="instagram-action-btn">
              <FontAwesomeIcon icon={faComment} />
            </button>
            <button className="instagram-action-btn">
              <FontAwesomeIcon icon={faShare} />
            </button>
          </div>
          <button 
            className={`instagram-action-btn ${bookmarked ? 'bookmarked' : ''}`}
            onClick={() => setBookmarked(!bookmarked)}
          >
            <FontAwesomeIcon icon={faBookmark} />
          </button>
        </div>
        
        {liked && <div className="instagram-likes">1 like</div>}
        
        <div className="instagram-caption">
          <span className="instagram-username">{user.username}</span>
          <span className="instagram-content">{content}</span>
        </div>
      </div>
    );
    
    const renderLinkedInPreview = () => (
      <div className="linkedin-post">
        <div className="linkedin-header">
          {user.avatar ? (
            <img src={user.avatar} alt="Profile" className="linkedin-avatar" />
          ) : (
            <div className="linkedin-avatar-placeholder" />
          )}
          <div className="linkedin-user-info">
            <span className="linkedin-name">{user.name}</span>
            <span className="linkedin-title">{user.title}</span>
            <div className="linkedin-post-meta">
              <span>1h</span>
              <span>·</span>
              <FontAwesomeIcon icon={faGlobe} />
            </div>
          </div>
          <FontAwesomeIcon icon={faEllipsisH} className="linkedin-more" />
        </div>
  
        <div className="linkedin-content">{content}</div>
  
        {media && (
          <div className="linkedin-media">
            {media.type.startsWith('image/') ? (
              <img src={URL.createObjectURL(media)} alt="Post media" />
            ) : (
              <video src={URL.createObjectURL(media)} controls />
            )}
          </div>
        )}
  
        <div className="linkedin-stats">
          <div className="linkedin-reactions">
            <span className="linkedin-reaction-icons">
              {liked && <span className="linkedin-like-icon">👍</span>}
            </span>
            <span>{liked ? '1' : '0'}</span>
          </div>
          <span className="linkedin-comments-count">0 comments</span>
        </div>
  
        <div className="linkedin-actions">
          <button 
            className={`linkedin-action-btn ${liked ? 'liked' : ''}`}
            onClick={() => setLiked(!liked)}
          >
            <FontAwesomeIcon icon={faThumbsUp} />
            <span>Like</span>
          </button>
          <button className="linkedin-action-btn">
            <FontAwesomeIcon icon={faComment} />
            <span>Comment</span>
          </button>
          <button className="linkedin-action-btn">
            <FontAwesomeIcon icon={faRetweet} />
            <span>Repost</span>
          </button>
          <button className="linkedin-action-btn">
            <FontAwesomeIcon icon={faShare} />
            <span>Send</span>
          </button>
        </div>
      </div>
    );
  
    const renderYouTubePreview = () => (
      <div className="youtube-post">
        <div className="youtube-video">
          {media && (
            <div className="youtube-video-container">
              <video src={URL.createObjectURL(media)} controls />
              <div className="youtube-duration">{user.duration}</div>
            </div>
          )}
        </div>
  
        <div className="youtube-info">
          <div className="youtube-header">
            {user.avatar ? (
              <img src={user.avatar} alt="Channel" className="youtube-avatar" />
            ) : (
              <div className="youtube-avatar-placeholder" />
            )}
            <div className="youtube-text">
              <h3 className="youtube-title">{content}</h3>
              <div className="youtube-meta">
                <span className="youtube-channel">{user.name}</span>
                <span className="youtube-views">{user.views} views</span>
              </div>
            </div>
          </div>
  
          <div className="youtube-actions">
            <button 
              className={`youtube-action-btn ${liked ? 'liked' : ''}`}
              onClick={() => setLiked(!liked)}
            >
              <FontAwesomeIcon icon={faThumbsUp} />
              <span>{liked ? '1' : '0'}</span>
            </button>
            <button className="youtube-action-btn">
              <FontAwesomeIcon icon={faShare} />
              <span>Share</span>
            </button>
            <button 
              className={`youtube-action-btn ${bookmarked ? 'saved' : ''}`}
              onClick={() => setBookmarked(!bookmarked)}
            >
              <FontAwesomeIcon icon={faBookmark} />
              <span>Save</span>
            </button>
          </div>
        </div>
      </div>
    );
  
    const renderThreadsPreview = () => (
      <div className="threads-post">
        <div className="threads-header">
          {user.avatar ? (
            <img src={user.avatar} alt="Profile" className="threads-avatar" />
          ) : (
            <div className="threads-avatar-placeholder" />
          )}
          <div className="threads-user-info">
            <span className="threads-username">{user.username}</span>
            {user.verified && (
              <span className="threads-verified">
                <FontAwesomeIcon icon={faCheck} />
              </span>
            )}
          </div>
          <FontAwesomeIcon icon={faEllipsisH} className="threads-more" />
        </div>
  
        <div className="threads-content">{content}</div>
  
        {media && (
          <div className="threads-media">
            {media.type.startsWith('image/') ? (
              <img src={URL.createObjectURL(media)} alt="Post media" />
            ) : (
              <video src={URL.createObjectURL(media)} controls />
            )}
          </div>
        )}
  
        <div className="threads-actions">
          <button 
            className={`threads-action-btn ${liked ? 'liked' : ''}`}
            onClick={() => setLiked(!liked)}
          >
            <FontAwesomeIcon icon={faHeart} />
          </button>
          <button className="threads-action-btn">
            <FontAwesomeIcon icon={faComment} />
          </button>
          <button 
            className={`threads-action-btn ${retweeted ? 'reposted' : ''}`}
            onClick={() => setRetweeted(!retweeted)}
          >
            <FontAwesomeIcon icon={faRetweet} />
          </button>
          <button className="threads-action-btn">
            <FontAwesomeIcon icon={faShare} />
          </button>
        </div>
      </div>
    );
  
    const renderTikTokPreview = () => (
      <div className="tiktok-post">
        <div className="tiktok-video-container">
          {media && (
            <div className="tiktok-video">
              <video 
                src={URL.createObjectURL(media)} 
                loop 
                onClick={() => setPlaying(!playing)}
              />
              <div className="tiktok-video-controls">
                <button 
                  className="tiktok-play-btn"
                  onClick={() => setPlaying(!playing)}
                >
                  <FontAwesomeIcon icon={playing ? faPause : faPlay} />
                </button>
                <button 
                  className="tiktok-mute-btn"
                  onClick={() => setMuted(!muted)}
                >
                  <FontAwesomeIcon icon={muted ? faVolumeMute : faVolumeUp} />
                </button>
              </div>
            </div>
          )}
  
          <div className="tiktok-sidebar">
            <button 
              className={`tiktok-action-btn ${liked ? 'liked' : ''}`}
              onClick={() => setLiked(!liked)}
            >
              <FontAwesomeIcon icon={faHeart} />
              <span>{liked ? '1' : '0'}</span>
            </button>
            <button className="tiktok-action-btn">
              <FontAwesomeIcon icon={faComment} />
              <span>0</span>
            </button>
            <button className="tiktok-action-btn">
              <FontAwesomeIcon icon={faBookmark} />
              <span>0</span>
            </button>
            <button className="tiktok-action-btn">
              <FontAwesomeIcon icon={faShare} />
              <span>0</span>
            </button>
          </div>
        </div>
  
        <div className="tiktok-content">
          <div className="tiktok-user-info">
            <span className="tiktok-username">@{user.username}</span>
            <span className="tiktok-name">{user.name}</span>
          </div>
          <div className="tiktok-description">{content}</div>
          <div className="tiktok-music">
            <FontAwesomeIcon icon={faMusic} />
            <span>Original Sound - {user.username}</span>
          </div>
        </div>
      </div>
    );


    switch (platform) {
      case 'facebook':
        return renderFacebookPreview();
      case 'x':
        return renderTwitterPreview();
      case 'instagram':
        return renderInstagramPreview();
      case 'linkedin':
        return renderLinkedInPreview();
      case 'youtube':
        return renderYouTubePreview();
      case 'threads':
        return renderThreadsPreview();
      case 'tiktok':
        return renderTikTokPreview();
      default:
        return null;
    }
  };


const AdvancedSettings = ({ selectedPlatforms, description, media, platformRules }) => {
  return (
    <div className="space-y-6 p-4 bg-white rounded-lg shadow">
      <div className="grid gap-6">
        {selectedPlatforms.map(platform => (
          <div key={platform} className="space-y-4">
            <h4 className="font-semibold text-lg">{platform.replace('_', ' ').toUpperCase()}</h4>
            
            {/* Media Requirements */}
            <div className="space-y-2">
              <h5 className="font-medium text-sm text-gray-600">Media Requirements</h5>
              <div className="grid grid-cols-2 gap-4 text-sm">
                <div>
                  <p>Aspect Ratio: {platformRules[platform].aspectRatio}</p>
                  {media && (
                    <p className={`mt-1 ${getAspectRatioStatus(media, platformRules[platform].aspectRatio) ? 'text-green-600' : 'text-red-600'}`}>
                      {getAspectRatioStatus(media, platformRules[platform].aspectRatio) ? '✓ Correct ratio' : '⚠ Incorrect ratio'}
                    </p>
                  )}
                </div>
                <div>
                  <p>Max File Size: {formatFileSize(platformRules[platform].maxFileSize)}</p>
                  {media && (
                    <p className={`mt-1 ${media.size <= platformRules[platform].maxFileSize ? 'text-green-600' : 'text-red-600'}`}>
                      Current: {formatFileSize(media.size)}
                    </p>
                  )}
                </div>
              </div>
            </div>
            
            {/* Content Analysis */}
            <div className="space-y-2">
              <h5 className="font-medium text-sm text-gray-600">Content Analysis</h5>
              <div className="space-y-3">
                <div>
                  <div className="flex justify-between text-sm mb-1">
                    <span>Character Count</span>
                    <span>{description.length} / {platformRules[platform].charLimit}</span>
                  </div>
                  <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div 
                      className={`h-full rounded-full transition-all ${description.length > platformRules[platform].charLimit ? 'bg-red-500' : 'bg-blue-500'}`}
                      style={{ width: `${Math.min(100, (description.length / platformRules[platform].charLimit) * 100)}%` }}
                    />
                  </div>
                </div>
                
                {/* Hashtag Analysis */}
                <div className="text-sm">
                  <p>Hashtags: {countHashtags(description)}</p>
                  <p>Mentions: {countMentions(description)}</p>
                  <p>URLs: {countUrls(description)}</p>
                </div>
              </div>
            </div>
            
            {/* Platform-Specific Recommendations */}
            <div className="bg-blue-50 p-3 rounded-md text-sm">
              <h5 className="font-medium text-blue-800 mb-2">Recommendations</h5>
              <ul className="list-disc pl-4 space-y-1 text-blue-700">
                {getPlatformRecommendations(platform)}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Utility functions
const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const countHashtags = (text) => (text.match(/#\w+/g) || []).length;
const countMentions = (text) => (text.match(/@\w+/g) || []).length;
const countUrls = (text) => (text.match(/https?:\/\/[^\s]+/g) || []).length;

const getAspectRatioStatus = (media, requiredRatio) => {
  // Implementation would depend on how you're handling media files
  return true; // Placeholder
};

const getPlatformRecommendations = (platform) => {
  const recommendations = {
    instagram_post: [
      'Use 3-5 relevant hashtags for best reach',
      'Square images perform best',
      'Tag relevant accounts to increase engagement'
    ],
    facebook_post: [
      'Posts with images get 2.3x more engagement',
      'Best time to post is between 1-4 PM',
      'Questions encourage comments'
    ],
    x: [
      'Use threads for longer content',
      'Include relevant handles to increase visibility',
      'Add media to increase engagement'
    ]
  };
  return recommendations[platform] || [];
};

export { PlatformPreview, AdvancedSettings };