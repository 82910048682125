import React, { useState } from 'react';
import '../../styles/moderntheme.css';

const availablePlatforms = ['Facebook', 'Instagram', 'Twitter', 'YouTube'];

const ConnectedAccounts = () => {
  const [connectedAccounts, setConnectedAccounts] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [notification, setNotification] = useState('');

  const handleAuth = async (platform) => {
    console.log(`Authenticating with ${platform}`);
    // Placeholder: Simulate OAuth response with mock profile data
    const mockProfileData = {
      platform,
      username: `${platform} User`,
      profilePhoto: `/${platform.toLowerCase()}-profile.jpg`,
      loggedInDate: new Date().toLocaleString(),
    };

    setConnectedAccounts((prev) => [...prev, mockProfileData]);
    setNotification(''); // Clear notification on success
  };

  const handleDisconnect = (platform) => {
    setConnectedAccounts((prev) =>
      prev.filter((account) => account.platform !== platform)
    );
  };

  const handleAddPlatform = () => {
    if (!selectedPlatform) return;

    const alreadyConnected = connectedAccounts.some(
      (acc) => acc.platform === selectedPlatform
    );

    if (alreadyConnected) {
      setNotification(`You have already connected ${selectedPlatform}.`);
    } else {
      handleAuth(selectedPlatform);
    }

    setSelectedPlatform(''); // Reset dropdown
  };

  const getAvailablePlatforms = () =>
    availablePlatforms.filter(
      (platform) =>
        !connectedAccounts.some((account) => account.platform === platform)
    );

  return (
    <div className="connectedaccounts-container">
      <h2>Connected Accounts</h2>

      {/* Add Account Dropdown */}
      <div className="add-account">
        <select
          value={selectedPlatform}
          onChange={(e) => setSelectedPlatform(e.target.value)}
        >
          <option value="">Select an App</option>
          {getAvailablePlatforms().map((platform) => (
            <option key={platform} value={platform}>
              {platform}
            </option>
          ))}
        </select>
        <button onClick={handleAddPlatform} className="btn add">
          Add +
        </button>
      </div>

      {/* Display Notification if Duplicate */}
      {notification && <p className="notification">{notification}</p>}

      {/* Connected Accounts List */}
      <div className="accounts-list">
        {connectedAccounts.map((account) => (
          <div key={account.platform} className="account-item">
            <img
              src={account.profilePhoto}
              alt={`${account.platform} Profile`}
              className="profile-photo"
            />
            <div className="account-info">
              <h3>{account.platform}</h3>
              <p>Username: {account.username}</p>
              <p>Logged in: {account.loggedInDate}</p>
              <button
                className="btn disconnect"
                onClick={() => handleDisconnect(account.platform)}
              >
                Disconnect
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConnectedAccounts;
