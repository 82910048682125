import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignInAlt,
  faUserPlus,
  faInfoCircle,
  faMoneyBillWave,
  faQuestionCircle,
  faTachometerAlt,
  faFileAlt,
  faUserFriends,
  faCog,
  faUpload
} from '@fortawesome/free-solid-svg-icons';
import logo from '../../logo/logo192.png';



const Navbar = ({ user, onLogout }) => {
  const [profilePicture, setProfilePicture] = useState(null);
  const [username, setUsername] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const burgerRef = useRef(null);
  const navigate = useNavigate();

  const UPLOADS_BASE_URL = 'http://localhost:5000/uploads/profile-pictures';
  const API_BASE_URL = 'http://localhost:5000/api/users';
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/profile`, {
          method: 'GET',
          headers: getAuthHeader(),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }

        const userData = await response.json();
        setUsername(userData.username || '');
        setProfilePicture(userData.profilePicture ? `${UPLOADS_BASE_URL}/${userData.profilePicture}` : '');
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const getAuthHeader = () => {
    const token = localStorage.getItem('authToken');
    const expiryTime = localStorage.getItem('tokenExpiry');
    if (!token || !expiryTime || Date.now() > expiryTime) {
      throw new Error('Token has expired or not found');
    }
    return {
      'Authorization': `Bearer ${token}`
    };
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current && !menuRef.current.contains(event.target) &&
        burgerRef.current && !burgerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleNavigate = (path) => {
    navigate(path);
    setIsOpen(false);
  };

  const handleLogout = () => {
    onLogout();
    navigate('/');
  };


  const renderMenuItem = (icon, text, path) => (
    <a 
      href="#" 
      className="navbar-item" 
      onClick={(e) => {
        e.preventDefault();
        handleNavigate(path);
      }}
    >
      <FontAwesomeIcon icon={icon} />
      <span>{text}</span>
    </a>
  );

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div 
          className="navbar-logo" 
          onClick={() => handleNavigate('/')}
          style={{ cursor: 'pointer' }}
        >
          TapPost
          <img src={logo} alt="Logo" className="navbar-logo-image"/>
        </div>
        <div className="navbar-actions">
          {user ? (
            <button onClick={() => handleNavigate('/upload')} className="btn upload-btn">
              <FontAwesomeIcon icon={faUpload} /> Upload
            </button>
          ) : (
            <button onClick={() => handleNavigate('/login')} className="btn login-btn">
              <FontAwesomeIcon icon={faSignInAlt} /> Login
            </button>
          )}
          <button
            ref={burgerRef}
            className={`navbar-burger ${isOpen ? 'active' : ''}`}
            onClick={toggleMenu}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
      <div ref={menuRef} className={`navbar-menu ${isOpen ? 'active' : ''}`}>
        {!user ? (
          <>
            {renderMenuItem(faUserPlus, 'Register', '/register')}
            {renderMenuItem(faInfoCircle, 'About', '/about')}
            {renderMenuItem(faMoneyBillWave, 'Pricing', '/pricing')}
            {renderMenuItem(faQuestionCircle, 'Help', '/help')}
          </>
        ) : (
          <>
            <div className="navbar-profile">
              {profilePicture ? (
                <img src={profilePicture} alt="Profile" className="profile-photo" />
              ) : (
                <div className="profile-photo default-logo">No Photo</div>
              )}
              <span className="profile-name">{username || 'Guest'}</span>
            </div>
            {renderMenuItem(faTachometerAlt, 'Dashboard', '/dashboard')}
            {renderMenuItem(faFileAlt, 'Posts', '/posts')}
            {renderMenuItem(faUserFriends, 'Connected Accounts', '/connected-accounts')}
            {renderMenuItem(faCog, 'Settings', '/settings')}
            <button onClick={handleLogout} className="btn logout-btn">Logout</button>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;