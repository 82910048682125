import React from 'react';
import '../../styles/moderntheme.css';

const HomePage = () => {
  return (
    <div className="home-page">
      <header className="hero">
        <div className="container-hero">
          <h1>Your Social Media, Reimagined</h1>
          <p>Experience the future of social media management. One platform, infinite possibilities.</p>
          <a href="#" className="btn">Get Started</a>
        </div>
      </header>
      
      <section className="features">
        <div className="container">
          <h2>Why Choose TapPost?</h2>
          <p className='features-grey'>Streamline your social media presence with our powerful suite of tools</p>
          <div className="feature-grid">
            <div className="feature-card">
              <h3>Multi-platform Integration</h3>
              <p>Connect and manage all your social media accounts in one place</p>
            </div>
            <div className="feature-card">
              <h3>Analytics Dashboard</h3>
              <p>Get insights and statistics for all your social media activities</p>
            </div>
            <div className="feature-card">
              <h3>Content Scheduler</h3>
              <p>Plan and schedule your posts across multiple platforms</p>
            </div>
          </div>
        </div>
      </section>
      
      <section className="cta">
        <div className="container">
          <h2>Ready to Transform Your Social Media Strategy?</h2>
          <a href="#" className="btn">Get Started Now</a>
        </div>
      </section>
    </div>
  );
};

export default HomePage;