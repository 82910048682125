import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/moderntheme.css';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

// Create axios instance with default config
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Single request interceptor with proper token handling
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      // Always ensure the token has the Bearer prefix
      const tokenWithBearer = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
      config.headers['Authorization'] = tokenWithBearer;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor for error handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      // Clear auth data on unauthorized
      localStorage.removeItem('authToken');
      localStorage.removeItem('userData');
      localStorage.removeItem('tokenExpiry');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

const Login = ({ onLogin, onNavigate }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Clear any existing tokens on component mount
    localStorage.removeItem('authToken');
    localStorage.removeItem('userData');
    localStorage.removeItem('tokenExpiry');
  }, []);

  const setupTokenRefresh = (expiryTime) => {
    const timeUntilExpiry = expiryTime - new Date().getTime();
    const refreshBuffer = 5 * 60 * 1000; // 5 minutes before expiry
  
    setTimeout(async () => {
      try {
        const response = await api.post('/users/refresh-token');
        if (response.data?.token) {
          const newExpiryTime = new Date().getTime() + 24 * 60 * 60 * 1000;
          localStorage.setItem('authToken', response.data.token);
          localStorage.setItem('tokenExpiry', newExpiryTime.toString());
          setupTokenRefresh(newExpiryTime);
        } else {
          handleLogout();
        }
      } catch (error) {
        console.error('Token refresh failed:', error);
        handleLogout();
      }
    }, Math.max(0, timeUntilExpiry - refreshBuffer));
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      if (!email || !password) {
        throw new Error('Please fill in all fields');
      }

      const response = await api.post('/users/login', {
        email: email.trim(),
        password: password
      });

      const { token, user } = response.data;

      if (!token || !user) {
        throw new Error('Invalid response from server');
      }

      // Store the token exactly as received from the server
      localStorage.setItem('authToken', token);
      localStorage.setItem('userData', JSON.stringify(user));

      // Setup token refresh
      const expiryTime = new Date().getTime() + 24 * 60 * 60 * 1000;
      localStorage.setItem('tokenExpiry', expiryTime.toString());
      setupTokenRefresh(expiryTime);

      // Verify token immediately
      try {
        await api.get('/users/profile');
        onLogin(user);
        window.location.href = '/dashboard';
      } catch (profileError) {
        console.error('Profile verification failed:', profileError);
        throw new Error('Failed to verify authentication');
      }

    } catch (error) {
      console.error('Login error:', error);
      
      setError(
        error.response?.data?.message ||
        error.message ||
        'An error occurred during login. Please try again.'
      );
      
      // Clean up any partial auth data
      localStorage.removeItem('authToken');
      localStorage.removeItem('userData');
      localStorage.removeItem('tokenExpiry');
      
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  return (
    <div className="form-container">
      <form className="form-card" onSubmit={handleLogin}>
        <h2>Login</h2>
        
        {error && (
          <div className="error-message" role="alert">
            {error}
          </div>
        )}
        
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled={isLoading}
          className={error ? 'error' : ''}
          aria-label="Email"
        />
        
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          disabled={isLoading}
          className={error ? 'error' : ''}
          aria-label="Password"
        />
        
        <button 
          type="submit" 
          disabled={isLoading}
          className={isLoading ? 'loading' : ''}
        >
          {isLoading ? 'Logging in...' : 'Login'}
        </button>
        
        <a 
          className="auth-link" 
          href="#register" 
          onClick={(e) => {
            e.preventDefault();
            onNavigate('/register');
          }}
        >
          Don't have an account? Register
        </a>
      </form>
    </div>
  );
};

export default Login;