import React, { useState, useEffect, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { faTimes } from '@fortawesome/free-solid-svg-icons'; // Close icon for the popup
import { getCroppedImg } from './cropImage'; // Helper to get the cropped image blo


const Settings = () => {
  const API_BASE_URL = 'http://localhost:5000/api/users';
  const UPLOADS_BASE_URL = 'http://localhost:5000/uploads/profile-pictures';

  // State declarations
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailSuccess, setEmailSuccess] = useState('');
  const [debugInfo, setDebugInfo] = useState('');

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');

  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePreview, setProfilePreview] = useState('');
  const [profileError, setProfileError] = useState('');
  const [profileSuccess, setProfileSuccess] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isCropping, setIsCropping] = useState(false); // Control the popup
  const [croppedArea, setCroppedArea] = useState(null); // Store cropped area

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/profile`, {
          method: 'GET',
          headers: getAuthHeader(),
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        
        const userData = await response.json();
        // Assuming userData contains fields: email, username, name, profilePicture
        setEmail(userData.email || '');
        setUsername(userData.username || '');
        setName(userData.name || '');
        setProfilePreview(userData.profilePicture ? `${UPLOADS_BASE_URL}/${userData.profilePicture}` : '');
      } catch (error) {
        //console.error('Error fetching user data:', error);
      }
    };
  
    fetchUserData();
  }, []);

  const getAuthHeader = () => {
    const token = localStorage.getItem('authToken');
    const expiryTime = localStorage.getItem('tokenExpiry');
    if (!token || !expiryTime || Date.now() > expiryTime) {
      throw new Error('Token has expired or not found');
    }
    return {
      'Authorization': `Bearer ${token}`
    };
  };

  const handleUpdateEmail = async (e) => {
    e.preventDefault();
    setEmailError('');
    setEmailSuccess('');
    setDebugInfo('');

    try {
      const response = await fetch(`${API_BASE_URL}/update-email`, {
        method: 'POST',
        headers: {
          ...getAuthHeader(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      const data = await response.json();

      if (response.ok) {
        setEmailSuccess('Email updated successfully!');
      } else if (response.status === 401) {
        handleLogout();
      } else {
        setEmailError(data.message || 'Failed to update email');
      }
    } catch (error) {
      setEmailError('Network error occurred');
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setPasswordError('');
    setPasswordSuccess('');

    if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/update-password`, {
        method: 'POST',
        headers: {
          ...getAuthHeader(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ oldPassword, newPassword })
      });

      const data = await response.json();

      if (response.ok) {
        setPasswordSuccess('Password updated successfully!');
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else if (response.status === 401) {
        handleLogout();
      } else {
        setPasswordError(data.message || 'Failed to update password');
      }
    } catch (error) {
      setPasswordError('Network error occurred');
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setProfileError('');
    setProfileSuccess('');

    const formData = new FormData();
    formData.append('username', username);
    formData.append('name', name);
    if (profilePicture) {
      formData.append('profilePicture', profilePicture);
    }

    try {
      const response = await fetch(`${API_BASE_URL}/update-profile`, {
        method: 'POST',
        headers: getAuthHeader(),
        body: formData
      });

      const data = await response.json();

      if (response.ok) {
        setProfileSuccess('Profile updated successfully!');
      } else if (response.status === 401) {
        handleLogout();
      } else {
        setProfileError(data.message || 'Failed to update profile');
      }
    } catch (error) {
      setProfileError('Network error occurred');
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(URL.createObjectURL(file));
      setIsCropping(true); // Open cropping popup
    }
  };

  const onCropComplete = useCallback((_, areaPixels) => {
    setCroppedArea(areaPixels); // Store cropped area in pixels
  }, []);

  const handleCropSave = async () => {
    try {
      const croppedImage = await getCroppedImg(profilePicture, croppedArea);
      const file = new File([croppedImage], 'cropped-profile.jpg', { type: 'image/jpeg' });

      setProfilePicture(file);
      setProfilePreview(URL.createObjectURL(file)); // Preview the cropped image
      setIsCropping(false); // Close cropping popup
    } catch (error) {
      //console.error('Error cropping image:', error);
    }
  };


  const handleLogout = () => {
    // Clear local storage and redirect to login or home page as needed
    localStorage.removeItem('authToken');
    localStorage.removeItem('tokenExpiry');
    localStorage.removeItem('user');
    // Redirect logic can go here
    alert('You have been logged out. Please log in again.');
  };

  const handleDeleteAccount = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/delete-account`, {
        method: 'DELETE',
        headers: getAuthHeader()
      });

      if (response.ok) {
        handleLogout();
      } else if (response.status === 401) {
        handleLogout();
      } else {
        const data = await response.json();
        alert(data.message || 'Failed to delete account');
      }
    } catch (error) {
      alert('Network error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="settings-container">
      {/* Debug Info Section */}
      {debugInfo && (
        <div className="debug-section" style={{ 
          padding: '10px', 
          margin: '10px 0', 
          backgroundColor: '#f0f0f0', 
          whiteSpace: 'pre-wrap' 
        }}>
          <h3>Debug Information:</h3>
          {debugInfo}
        </div>
      )}
      {/* Email Section */}
      <section className="settings-section">
        <h2>Update Email</h2>
        <form onSubmit={handleUpdateEmail}>
          <div className="form-group">
            <label>New Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          {emailError && <div className="error-message">{emailError}</div>}
          {emailSuccess && <div className="success-message">{emailSuccess}</div>}
          <button type="submit">Update Email</button>
        </form>
      </section>

      {/* Password Section */}
      <section className="settings-section">
        <h2>Change Password</h2>
        <form onSubmit={handleUpdatePassword}>
          <div className="form-group">
            <label>Current Password:</label>
            <input
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>New Password:</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Confirm New Password:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          {passwordError && <div className="error-message">{passwordError}</div>}
          {passwordSuccess && <div className="success-message">{passwordSuccess}</div>}
          <button type="submit">Update Password</button>
        </form>
      </section>

      {/* Profile Section */}
      <section className="settings-section">
        <h2>Profile Settings</h2>
        <form onSubmit={handleUpdateProfile}>
          <div className="form-group">
            <label>Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Profile Picture:</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
            {profilePreview && (
              <div className="profile-preview">
                <img src={profilePreview} alt="Profile preview" />
              </div>
            )}
          </div>
          {profileError && <div className="error-message">{profileError}</div>}
          {profileSuccess && <div className="success-message">{profileSuccess}</div>}
          <button type="submit">Update Profile</button>
        </form>
      </section>
{/* Cropping Popup */}
{isCropping && (
        <div className="crop-popup">
          <div className="crop-container">
            <Cropper
              image={profilePicture}
              crop={crop}
              zoom={zoom}
              aspect={1} // 1:1 aspect ratio for square cropping
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
            <div className="crop-controls">
              <button onClick={handleCropSave}>Save</button>
              <button onClick={() => setIsCropping(false)}>
               Cancel
              </button>
            </div>
          </div>
        </div>
      )}
  
      {/* Delete Account Section */}
      <section className="settings-section">
        <h2>Delete Account</h2>
        <button onClick={() => setShowDeleteConfirm(true)}>Delete Account</button>
        {showDeleteConfirm && (
          <div className="confirmation-modal">
            <p>Are you sure you want to delete your account? This action cannot be undone.</p>
            <button onClick={handleDeleteAccount} disabled={isLoading}>
              {isLoading ? 'Deleting...' : 'Yes, delete my account'}
            </button>
            <button onClick={() => setShowDeleteConfirm(false)}>Cancel</button>
          </div>
        )}
      </section>
    </div>
  );
};

export default Settings;
