import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Navbar from './components/common/Navbar.js';
import Footer from './components/common/Footer.js';
import HomePage from './components/common/HomePage.js';
import Login from './components/common/Login.js';
import Dashboard from './components/dashboard/Dashboard.js';
import Settings from './components/common/Settings.js';
import Upload from './components/common/Upload.js';
import ConnectedAccounts from './components/common/ConnectedAccounts.js';
import Register from './components/auth/Register.js';
import Terms from './components/common/Terms.js';
import Privacy from './components/common/Privacy.js';

const decodeToken = (token) => {
  try {
    const payload = token.split('.')[1];
    const decoded = JSON.parse(atob(payload));
    return decoded;
  } catch (error) {
    console.error('Invalid token:', error);
    return null;
  }
};

function App() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      const decoded = decodeToken(token);
      if (decoded) {
        const userData = {
          username: decoded.username || 'Guest',
          profilePhoto: decoded.profilePhoto || null,
        };
        setUser(userData);
      } else {
        localStorage.removeItem('authToken');
      }
    }
  }, []);

  

  const handleLogin = (userData) => {
    console.log("User logged in:", userData);
    setUser(userData);
    navigate('/dashboard');
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('authToken');
    navigate('/');
  };

  // Array of routes that shouldn't show footer
  const hideFooterPaths = ['/login', '/register'];
  const currentPath = window.location.pathname;

  return (
    <div className="App">
      <Navbar user={user} onLogout={handleLogout} />
      <main className="container">
        <Routes>
          <Route path="/" element={user ? <Navigate to="/dashboard" /> : <HomePage />} />
          <Route path="/login" element={
            user ? <Navigate to="/dashboard" /> : <Login onLogin={handleLogin} />
          } />
          <Route path="/register" element={
            user ? <Navigate to="/dashboard" /> : <Register />
          } />
          <Route path="/dashboard" element={
            user ? <Dashboard /> : <Navigate to="/login" />
          } />
          <Route path="/settings" element={
            user ? <Settings user={user} /> : <Navigate to="/login" />
          } />
          <Route path="/upload" element={
            user ? <Upload user={user} /> : <Navigate to="/login" />
          } />
          <Route path="/connected-accounts" element={
            user ? <ConnectedAccounts /> : <Navigate to="/login" />
          } />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </main>
      {!hideFooterPaths.includes(currentPath) && <Footer />}
    </div>
  );

}

export default App;