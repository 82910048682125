// frontend/src/components/auth/Register.js
import React, { useState } from 'react';
import '../../styles/moderntheme.css'; // Ensure you import your CSS

const Register = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleRegister = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('http://localhost:5000/api/users/register', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ email, username, password }),
      });

      if (response.ok) {
        setMessage('Registration successful! You will be redirected to the login page.');
        setTimeout(() => {
          window.location.href = '/login'; // Redirect after 3 seconds
        }, 3000);
      } else {
        const errorData = await response.json();
        setMessage(`Registration failed: ${errorData.message || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Registration failed:', error);
      setMessage('Something went wrong! Check the console for more details.');
    }
  };

  return (
    <div className="form-container">
      <form className="form-card" onSubmit={handleRegister}>
        <h2>Register</h2>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Register</button>
        {message && <p className="status-message">{message}</p>} {/* Display message */}
      </form>
    </div>
  );
};

export default Register;