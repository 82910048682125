import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faCamera, faTimes, faClock, faPaperPlane, faArrowUp, faArrowDown,faEye, faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'; // faAt is imported from the solid icons
import { faFacebookSquare, faInstagram, faThreads, faXTwitter, faYoutube, faLinkedin, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { PlatformPreview, AdvancedSettings } from './PlatfromComponents.js';

const platforms = [
  { name: 'Facebook', value: 'facebook', icon: faFacebookSquare, requiresMedia: false },
  //{ name: 'Facebook Story', value: 'facebook_story', icon: faFacebookSquare, requiresMedia: true },
  { name: 'Instagram', value: 'instagram', icon: faInstagram, requiresMedia: true },
  //{ name: 'Instagram Story', value: 'instagram_story', icon: faInstagram, requiresMedia: true },
  { name: 'X', value: 'x', icon: faXTwitter, requiresMedia: false },
  { name: 'Threads', value: 'threads', icon: faThreads, requiresMedia: false }, 
  { name: 'YouTube', value: 'youtube', icon: faYoutube, requiresMedia: true, requiresVideo: true },
  { name: 'Linkdin', value: 'linkdin', icon: faLinkedin, requiresMedia: true, requiresVideo: false },
  { name: 'TikTok', value: 'tiktok', icon: faTiktok, requiresMedia: true, requiresVideo: true },
];

const platformRules = {
  facebook: { aspectRatio: '1:1', charLimit: 63000, videoDuration: null, maxFileSize: 8 * 1024 * 1024 },
  //facebook_story: { aspectRatio: '9:16', charLimit: 2000, videoDuration: 120, maxFileSize: 4 * 1024 * 1024 },
  instagram: { aspectRatio: '1:1', charLimit: 2200, videoDuration: 60, maxFileSize: 30 * 1024 * 1024 },
  //instagram_story: { aspectRatio: '9:16', charLimit: 2200, videoDuration: 15, maxFileSize: 4 * 1024 * 1024 },
  x: { aspectRatio: '16:9', charLimit: 280, videoDuration: null, maxFileSize: 5 * 1024 * 1024 },
  threads: { aspectRatio: '1:1', charLimit: 500, videoDuration: null, maxFileSize: 5 * 1024 * 1024 },
  youtube: { aspectRatio: '16:9', charLimit: 5000, titleCharLimit: 100, videoDuration: 43200, maxFileSize: null },
  tiktok: { aspectRatio: '9:16', charLimit: 150, videoDuration: 180, maxFileSize: 500 * 1024 * 1024 },
  linkdin: { aspectRatio: '4:5', charLimit: 1300, videoDuration: 600, maxFileSize: 200 * 1024 * 1024 },
};

const ContentPreview = ({ platform, content, media, platformRules }) => {
  return (
    <div className="content-preview">
      <h3 className="platform-name">{platform.charAt(0).toUpperCase() + platform.slice(1)}</h3>
      <PlatformPreview
        platform={platform}
        content={content}
        media={media}
        platformRules={platformRules}
      />
    </div>
  );
};

const UploadPage = () => {
  const [media, setMedia] = useState(null);
  const [description, setDescription] = useState('');
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [youtubeTitle, setYoutubeTitle] = useState('');
  const [youtubeThumbnail, setYoutubeThumbnail] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showPlatformTagging, setShowPlatformTagging] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [showCameraPermission, setShowCameraPermission] = useState(false);
  const fileInputRef = useRef(null);
  const cameraInputRef = useRef(null);
  const textareaRef = useRef(null);
  const [showPreview, setShowPreview] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (showPlatformTagging && textareaRef.current) {
      const textareaRect = textareaRef.current.getBoundingClientRect();
      const containerRect = textareaRef.current.parentElement.getBoundingClientRect();
      const popup = document.querySelector('.platform-tagging-popup');
  
      // Adjust for scrolling
      const topPosition = textareaRect.bottom - containerRect.top + window.scrollY;
      const leftPosition = textareaRect.left - containerRect.left + window.scrollX;
  
      popup.style.top = `${topPosition}px`;
      popup.style.left = `${leftPosition}px`;
      popup.style.width = `${textareaRect.width}px`;
    }
  }, [showPlatformTagging]);
  
  const handleShowPreview = () => {
    const { taggedContent } = getPlatformSpecificContent(description);
    setShowPreview(true);
  };

  const handleMediaCapture = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMedia(file);
    }
  };

  const handleCameraCapture = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      setShowCameraPermission(true);
    } else {
      setAlertMessage("Your device doesn't support camera access.");
      setShowAlert(true);
    }
  };

  const handleCameraPermission = (allow) => {
    setShowCameraPermission(false);
    if (allow) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(() => {
          cameraInputRef.current.click();
        })
        .catch((error) => {
          console.error("Camera access error:", error);
          setAlertMessage("Failed to access camera. Please check your permissions.");
          setShowAlert(true);
        });
    }
  };

  const handlePlatformSelection = (platform) => {
    setSelectedPlatforms(prev => 
      prev.includes(platform) ? prev.filter(p => p !== platform) : [...prev, platform]
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prev) => 
      prev === selectedPlatforms.length - 1 ? 0 : prev + 1
    );
  };
  
  const prevSlide = () => {
    setCurrentIndex((prev) => 
      prev === 0 ? selectedPlatforms.length - 1 : prev - 1
    );
  };
  const handleDescriptionChange = (e) => {
  const newDescription = e.target.value;
  setDescription(newDescription);
  setCursorPosition(e.target.selectionStart);

  // Show the platform tagging popup only when the user types "@"
  setShowPlatformTagging(newDescription.slice(-1) === '@');
};

const insertPlatformTag = (platform) => {
  // Get the text before and after the cursor
  const before = description.slice(0, cursorPosition).replace(/@$/, ''); // Remove any extra "@"
  const after = description.slice(cursorPosition);

  // Insert the platform tag at the cursor position
  const newDescription = `${before}@[${platform.value},]${after}`;
  setDescription(newDescription);
  setShowPlatformTagging(false);

  // Set the new cursor position after the inserted platform tag
  const newCursorPosition = before.length + platform.value.length + 3; // Adjust for the inserted tag

  // Move the cursor to the correct position and focus the textarea
  setTimeout(() => {
    textareaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
    textareaRef.current.focus();
  }, 0);
};

const getPlatformSpecificContent = (description) => {
  const taggedContent = {};
  
  // Initialize content for all selected platforms
  selectedPlatforms.forEach(platform => {
    taggedContent[platform] = [];
  });
  
  // Split the description into segments based on tags, handling empty content
  const regex = /@\[([^,]+),([^\]]*)\]/g;
  let lastIndex = 0;
  let match;
  
  while ((match = regex.exec(description)) !== null) {
    // Add untagged text before the tag to ALL platforms
    const textBefore = description.slice(lastIndex, match.index).trim();
    if (textBefore) {
      selectedPlatforms.forEach(platform => {
        taggedContent[platform].push(textBefore);
      });
    }
    
    // Add tagged content only to the specified platform
    const targetPlatform = match[1].toLowerCase().trim();
    const taggedText = match[2].trim();
    
    // Only process if there's actual content in the tag
    if (taggedText && taggedContent[targetPlatform]) {
      taggedContent[targetPlatform].push(taggedText);
    }
    
    lastIndex = regex.lastIndex;
  }
  
  // Add any remaining untagged text to ALL platforms
  const remainingText = description.slice(lastIndex).trim();
  if (remainingText) {
    // Filter out any remaining empty tags before adding the text
    const cleanedText = remainingText.replace(/@\[[^,]+,\]/g, '').trim();
    if (cleanedText) {
      selectedPlatforms.forEach(platform => {
        taggedContent[platform].push(cleanedText);
      });
    }
  }
  
  // Clean up the content - remove empty tags and extra spaces
  Object.keys(taggedContent).forEach(platform => {
    taggedContent[platform] = taggedContent[platform]
      .join(' ')
      .replace(/@\[[^,]+,\]/g, '') // Remove any remaining empty tags
      .replace(/\s+/g, ' ') // Replace multiple spaces with single space
      .trim();
  });
  
  return { taggedContent };
};



const handlePost = () => {
  const { taggedContent } = getPlatformSpecificContent(description);
  
  const postOutput = [];
  
  // Format the output for each selected platform
  selectedPlatforms.forEach(platform => {
    if (taggedContent[platform]) {
      const content = taggedContent[platform].trim();
      if (content) { // Only add if there's actual content
        postOutput.push(
          `${platform.toUpperCase().replace('_', ' ')}: ${content}`
        );
      }
    }
  });
  
  // Log the formatted output
  if (postOutput.length > 0) {
    console.log('Posting content:', postOutput.join('\n'));
    setAlertMessage('Content posted successfully!');
  } else {
    setAlertMessage('No content to post!');
  }
  setShowAlert(true);
};
  
  
  

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(prev => !prev);
};


  return (
    <div className="upload-page">
      <h1>Create Post</h1>
      <div className="media-buttons">
        <button onClick={() => fileInputRef.current.click()}>
          <FontAwesomeIcon icon={faUpload} /> Upload
        </button>
        <button onClick={() => cameraInputRef.current.click()}>
          <FontAwesomeIcon icon={faCamera} /> Capture
        </button>
        <input type="file" accept="image/*,video/*" onChange={handleMediaCapture} ref={fileInputRef} style={{ display: 'none' }} />
        <input type="file" accept="image/*,video/*" capture="environment" onChange={handleMediaCapture} ref={cameraInputRef} style={{ display: 'none' }} />
      </div>

      {media && (
        <div className="media-preview">
          {media.type.startsWith('image/') ? (
            <img src={URL.createObjectURL(media)} alt="Uploaded media" onLoad={() => URL.revokeObjectURL(media)} />
          ) : (
            <video src={URL.createObjectURL(media)} controls onLoad={() => URL.revokeObjectURL(media)} />
          )}
          <button onClick={() => setMedia(null)}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}

      <div className="description-container">
        <textarea
          ref={textareaRef}
          placeholder="What's on your mind? Use @ to tag platforms"
          value={description}
          onChange={handleDescriptionChange}
        />
        {showPlatformTagging && (
          <div className="description-container">
          <textarea ref={textareaRef} placeholder="What's on your mind? Use @ to tag platforms" value={description} onChange={handleDescriptionChange} />
          {showPlatformTagging && (
            <div className="platform-tagging-popup">
              {platforms.map(platform => (
                <div key={platform.value} onClick={() => insertPlatformTag(platform)}>
                  <FontAwesomeIcon icon={platform.icon} /> {platform.name}
                </div>
              ))}
            </div>
          )}
        </div>
        )}
              </div>

              <div className="platforms-container">
          <h2>Select Platforms</h2>
          <div className="platform-grid">
            {platforms.map((platform) => (
              <div
                key={platform.value}
                className={`platform-item ${selectedPlatforms.includes(platform.value) ? 'selected' : ''}`}
                onClick={() => handlePlatformSelection(platform.value)}
              >
                <FontAwesomeIcon icon={platform.icon} />
                <span className="platform-name">{platform.name}</span>
              </div>
            ))}
          </div>
        </div>

      {selectedPlatforms.includes('youtube') && (
        <div className="youtube-options">
          <input
            type="text"
            placeholder="YouTube Title"
            value={youtubeTitle}
            onChange={(e) => setYoutubeTitle(e.target.value)}
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setYoutubeThumbnail(e.target.files[0])}
          />
        </div>
      )}

      <div className="post-options">
        <button className="btn btn-preview" onClick={handleShowPreview}>
          <FontAwesomeIcon icon={faEye} /> Preview
        </button>
        <button className="btn btn-post" onClick={handlePost}>
          <FontAwesomeIcon icon={faPaperPlane} /> Post Now
        </button>
        {/*<button className="btn btn-schedule" onClick={() => setScheduleTime(new Date())}>
          <FontAwesomeIcon icon={faClock} /> Schedule
        </button>*/}
      </div>
      {showPreview && (
      <div
        className="preview-overlay"
        /*onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}*/
      >
        <div className="preview-container">
          <div className="preview-header">
            <h2>Content Preview</h2>
            <button className="close-preview" onClick={() => setShowPreview(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="preview-grid">
            <button className="nav-arrow left-arrow" onClick={prevSlide}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>

            {/* Single preview display */}
            {(() => {
              const { taggedContent } = getPlatformSpecificContent(description);
              const currentPlatform = selectedPlatforms[currentIndex];
              return (
                <ContentPreview
                  key={currentPlatform}
                  platform={currentPlatform}
                  content={taggedContent[currentPlatform]}
                  media={media}
                  platformRules={platformRules}
                />
              );
            })()}

            <button className="nav-arrow right-arrow" onClick={nextSlide}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>

            {/* Platform indicators */}
            <div className="platform-indicators">
              {selectedPlatforms.map((platform, idx) => (
                <button
                  key={platform}
                  onClick={() => setCurrentIndex(idx)}
                  className={`indicator ${idx === currentIndex ? 'active' : ''}`}
                />
              ))}
            </div>
          </div>
          <div className="preview-actions">
            <button className="btn btn-secondary" onClick={() => setShowPreview(false)}>
              Edit
            </button>
            <button className="btn btn-primary" onClick={handlePost}>
              Confirm & Post
            </button>
          </div>
        </div>
      </div>
    )}
      <button className="advanced-dropdown" onClick={toggleDropdown}>
        <span className="dropdown-text">Advanced</span>
        <FontAwesomeIcon icon={isDropdownOpen ? faArrowUp : faArrowDown} className="dropdown-arrow" />
      </button>
      
      {isDropdownOpen && (
        <AdvancedSettings
          selectedPlatforms={selectedPlatforms}
          description={description}
          media={media}
          platformRules={platformRules}
        />
      )}
      {/*{scheduleTime && (
        <input
          type="datetime-local"
          value={scheduleTime.toISOString().slice(0, 16)}
          onChange={(e) => setScheduleTime(new Date(e.target.value))}
          className="schedule-input"
        />
      )}*/}

      {showAlert && (
        <div className="alert-overlay">
          <div className="alert-box">
            <h2>Notification</h2>
            <p>{alertMessage}</p>
            <button className="btn" onClick={() => setShowAlert(false)}>OK</button>
          </div>
        </div>
      )}

      {showCameraPermission && (
        <div className="alert-overlay">
          <div className="alert-box">
            <h2>Camera Permission</h2>
            <p>This app wants to use your camera. Do you allow?</p>
            <div className="alert-actions">
              <button className="btn btn-cancel" onClick={() => handleCameraPermission(false)}>Deny</button>
              <button className="btn btn-confirm" onClick={() => handleCameraPermission(true)}>Allow</button>
            </div>
          </div>
        </div>
      )}

      <div className="app-specific-description">
        <h2>Platform-Specific Content</h2>
        <p>
          Use @ to tag platforms in your description.
          Content within the brackets will only be shown on that specific platform.
        </p>
        <p className="example">
          Example: Hello everyone! @[facebook_post,Check out our Facebook page] @[twitter,Follow us on Twitter]
        </p>
        <p>
          Facebook: Hello everyone! Check out our Facebook page
        </p>
        <p>
          Twitter: Hello everyone! Follow us on Twitter
        </p>
      </div>
    </div>
  );
};

export default UploadPage;
      