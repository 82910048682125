import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="footer-description">
          TapPost helps you manage your social media presence in one place.
        </p>
        <p className="footer-email">
          <a href="mailto:support@tappost.co">support@tappost.co</a>
        </p>
      </div>
      <div className="footer-links">
        <div className="social-links">
          <h4>Follow Us</h4>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        </div>
        <div className="legal-links">
          <Link to="/terms">Terms</Link> | <Link to="/privacy">Privacy</Link>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© {currentYear} TapPost. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
                     