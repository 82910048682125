import React, { useEffect, useState } from 'react';

const Dashboard = () => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      window.location.href = '/login';
      return; // Exit early if no token
    }

    try {
      // Ensure the token has three parts
      const parts = token.split('.');
      if (parts.length !== 3) {
        throw new Error('Invalid token structure');
      }

      // Decode the payload
      const payload = JSON.parse(atob(parts[1]));
      setUsername(payload.username || ''); // Handle case where username might not exist

    } catch (err) {
      console.error('Token decoding error:', err);
      setError('Failed to retrieve user data. Please log in again.');
      // Optionally, you can redirect to login if there's an error
      // window.location.href = '/login';
    }
  }, []);

  if (error) {
    return <h1>{error}</h1>; // Display error message
  }

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h1>Welcome, {username}!</h1>
      </header>
      <main className="dashboard-main">
        <div className="dashboard-card">
          <h2>Your Stats</h2>
          <p>Here you can view your stats and performance.</p>
        </div>
        <div className="dashboard-card">
          <h2>Your Recent Activity</h2>
          <p>Check out your recent activities and updates.</p>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
